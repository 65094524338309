.navbar {
    background-color: #0079bf;
    display: flex;
    justify-content: start;
    padding: 5px 0;
  }
  
  .navItem {
    margin: 0 5px;
  }
  
  .navLink {
    color: white;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 4px;
    background-color: transparent;
    text-decoration: none;
  }
  
  .navLink:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  
  .navLinkActive {
    background-color: rgba(255, 255, 255, 0.3);
  }
  
  .navLinkIcon {
    margin-right: 3px;
    font-size: 0.9rem;
  }
  
.header {
  background-color: black;
  color:white;
  padding: 20px;
  text-align: center;
}

.colorTable {
  margin: 0 auto;
  border-collapse: collapse;
}

.colorCell {
  padding: 10px;
  text-align: left;
  vertical-align: middle;
}

.colorButton {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-right: 10px;
}

.colorName {
  font-size: 14px;
  vertical-align: middle;
}

.red { background-color: #FF0000; }
.orange { background-color: #FFA500; }
.yellow { background-color: #FFFF00; }
.green { background-color: #008000; }
.blue { background-color: #0000FF; }
.purple { background-color: #800080; }
.pink { background-color: #FFC0CB; }
.black { background-color: #000000; }
.grey { background-color: #808080; }
.lime { background-color: #00FF00; }
.sky { background-color: #87CEEB; }
